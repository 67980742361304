<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='position: relative; display: flex; width: 100%; height: calc(100vh - 190px);'>
		        <div style='float: top;'>
			        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>Material-Entry 1.0</div>
			        <br/>
			        
			        <div style='display: inline-flex; width: 45%;'>
				    <InputDatePick :auto="false" 
	                            @change="dateChanged" v-model="fromDate"/>  
	                            </div>
	                            -
	                <div style='display: inline-flex; width: 45%;'>
	                <InputDatePick :auto="false" @change="dateChanged" v
	                            v-model="untilDate"/>     
	                 </div>  
					 					 
	                <br/>
	                <hr/>
			         <InputSearch v-model="searchTerm" topic='material name' @enter="getData()" usage='dashboard'/>
			        <br/>  
	                <button :title='"reload screen"' class='button myButton' :style="getAppStyle()" @click="getData()"><mdicon :size="18" name="reload" /></button> 
					<button :title='"create excel report"' class='button myButton' :style="getAppStyle()" @click="createExcel()"><mdicon :size="18" name="microsoft-excel" /></button> 

					<br/>
					<div v-for="(prov,pi) in validProvider" :key="'prov'+pi" >
						<button 
					        :title="'enter new available material for '+prov.label" class='button myCreateButton' :style="getAppStyle()" 
					        @click="$refs.copyWizzard.open(data.provider, data.languages, data.stati, data.mediaFamilies, data.copyTypes, null, prov, searchTerm)"><mdicon :size="26" name="new-box" /> <span style="padding-left: 5pt;">{{prov.label}}</span></button> 
					</div>				
					
				</div>
		    	<div style='position:absolute; bottom:50px;'>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	</div>
		    	

		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 78%;'> 
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div v-if="ready" style='width: calc(100%); height: calc(100vh - 110px);'  >
			
			<button v-for="(xStat,si) in data.stati" :key="'stat'+si" 
			    :class="'button myTab'+(status==xStat.id?' myTabActive':'')" :active="status==xStat.id" @click="status=xStat.id">
				{{xStat.label.toUpperCase()}}
				<span style="margin-left: 4pt;" v-if="nStati[xStat.id]"  class='w3-badge w3-blue'>{{nStati[xStat.id]}}</span>
			</button>
		
					
	    	<div style='float: top; width: 100%; height: 18pt; overflow-y: scroll;'>
			    <div style='float: top;'>
				    <div v-for="(col,idx) in selectedColumns" :key="'ColH_'+idx" class='floatLeft tableHeader' :style='getColumnStyle(col, idx)'
						@click='setOrderBy(col)'
						>
				       <div class='header' 
				            :style="isDarkMode()?'background-color: #666; color: #fff;':'background-color: #ddd; color: #000;'">
				       		<div class='ltd' :title="col.name"> {{col.name}}<sub>{{getOrderBy(col)}}</sub></div>
				       	</div>
				    </div>
				   
			    </div>  <!-- float: top -->   
			</div> <!-- HEADER --> 
	    <!-- TABLE -->
							
	    <div v-if="ready" style='width: 100%; height: calc(80vH) !important; overflow-y: scroll;' >
			    	<div   style='float: top; width: 100%;height: 100%;' >
						
			    	    <div v-for="(line,idx) in limit(data.entries, searchTerm)" 
			    	         class='reqLine' :key="'DAT_'+idx" :ref="'DAT_'+idx" :id="'DAT_'+idx"
							 @click="openLine( line)"
							 @contextmenu="ctxMenu($event, line, idx)"
			    	         @mouseenter="hoverLine('DAT_'+idx)"
			    	         @mouseleave="leaveLine('DAT_'+idx)"
							 
			    	        >

			    	        <div v-for="(col,idx2) in selectedColumns" :key="'CS_'+idx+'_'+idx2" :class="'floatLeft'"  :style='getColumnStyle(col, idx2)'>
			    	            <div :class="'reqDetail dontWrap '+ col.class" :style='getColumnDetailStyle(col, line)' >
						            <div v-if="col.type==='icon'" :title="col.tooltip" style='width: 100%; height: 100%;'>
						                <div v-if="!line.copyId" :class="'reqDetailNB dontWrap '+col.class" 
										     style='width: 100%; height: 100%;'>
						       				
						       			</div>
						       			<div v-else  style='width: 100%; height: 100%;' :class="'reqDetailNB dontWrap '+col.class"> 
											<mdicon :size="18" name="check" />
						       			</div>
						       		</div>
						    
						       		<div v-else :title="line.display[col.id]"  >
										
						       			{{line.display[col.id]}}
						       		</div>
						       	</div>
						     </div> 
							 	
				    	        
			    	    </div>
			    		
			    	</div> 
		    	</div>  <!-- TABLE -->
    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData && contextData.data"  slot-scope="{ contextData }">
        
		<ContextMenuItem v-if="contextData.data.id" @clicked="$refs.menu.close(); openModule('CopyEntry', contextData.data.id); ">
        	Edit {{tr('CopyEntry')}}
        </ContextMenuItem>
		<ContextMenuItem v-if="contextData.data.copyId" @clicked="$refs.menu.close(); openModule('Copy', contextData.data.copyId); ">
	    	Edit {{tr('Copy')}}
	    </ContextMenuItem>
		<ContextMenuSep/>
		<ContextMenuItem v-if="contextData.data.id && !contextData.data.copyId" @clicked="$refs.menu.close(); deleteEntry(contextData.data.id); ">
		    Delete {{tr('CopyEntry')}}
		</ContextMenuItem>
        

        
      </template>    
	</ContextMenu>
    <GConfirm ref='confirm'/>
    <PDFViewer name='pdfView' ref='pdfView' />
    <GFWEOpenEditor ref='editor'></GFWEOpenEditor>
	<ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
	
	<CopyWizard ref='copyWizzard' @reload="getData()" @deleteEntry="deleteEntry"></CopyWizard>
	<CopyView ref='copyViewer' @reload="getData()" @deleteEntry="deleteEntry"></CopyView>
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, reportAPI, dealReportAPI, clientReportAPI, copyWorkflowAPI, invoiceAPI, setReload, myLocale, showError, formatNumber} from '@/variables.js';
import { setGoBack } from '@/breadCrumb.js';
import { tr } from '@/translate.js';
import { forceFileDownload} from '@/fileUtil.js';
import { getAppStyle, isDarkMode, initAppMode, getFG, getBG, getBGColor } from '@/AppStyle.js';
import { printTimeMMSS, printTimeHHMM } from '@/basicTimeFN.js';
import {store, restore} from '@/CopyScreen.js';
import InputTime from '@/components/inputElements/InputTime2';
import InputText from '@/components/inputElements/InputText';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick3'; 
import GSelectMSimple from '@/components/GSelectMSimple'; 
import ProgressBar from '@/components/ProgressBar';
import PDFViewer from '@/components/PDFViewer';
import GConfirm from '@/components/GConfirm';
import CopyWizard from '@/components/misc/CopyWizard';
import CopyView from '@/components/misc/CopyView';
import InputSearch from '@/components/inputElements/InputSearch';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { format } from '@/Numbers.js';
var momentTZ = require('moment-timezone');

var timers = [];
var lastMarked = "-";
import JQuery from "jquery";
let $ = JQuery;
export default {
  name: 'NEXT_CopyENTRY_View',
  components : {
   'app-switch': Switch, InputDatePick, 
   ProgressBar, InputSearch, GConfirm, ContextMenu, ContextMenuItem, ContextMenuSep, CopyWizard, CopyView
  },
  data () {
    return {
      data: {},
	  nStati: [],
	  validProvider: [],
      fromDate: new Date(),
      untilDate: new Date(),
      showProgressBar: false,
      searchTerm: "",
	  status: 0,
      search: "",
      dealId: 0,
      dealData: {},
      dataSave: {},
      pbTitle: "",
      pbAction: "",
      controlsRight: false,
      ready: false,
      fontSize: 11,
	  houseNumberPerPlayout: false,
      widthCalculated: 800,
      tr, getAppStyle, getBGColor, format, isDarkMode,
      orderBy: [],
      p: 1,
      availableColumns: [
	        {id: 10, name: "Name", show: "name", type: 'txt', disabled: true, class:'left', w: 14},
			{id: 20, name: "NameLog", show: "shortname", type: 'txt', disabled: true, class:'left', w: 14},
			{id: 30, name: "Status", show: "statusValue", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 40, name: "Duration", show: "duration", type: 'time', disabled: true, class:'centered', w: 3},
			{id: 50, name: "Provider", show: "provider", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 54, name: "MediaFamily", show: "mediaFamily", type: 'txt', disabled: true, class:'centered', w: 4},
			{id: 56, name: "Type", show: "type", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 58, name: "Comment", show: "comment", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 60, name: "Received", show: "received", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 70, name: "Houseno", show: "houseno", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 80, name: "Assigned", show: "assigned", type: 'txt', disabled: true, class:'centered', w: 3},
			{id: 90, name: tr('Copy'), show: "filmstrip", type: 'icon', disabled: true, class:'centered', w: 2}
      ],
      selectedIN: "",
      selectedDefault: [10,20,30,40,50,54,56,58,60,70,80,90],
      selectedSave: [10,20,30,40,50,54,56,58,60,70,80,90],
      selectedDefaultD: [10,20,30,40,50,54,56,58,60,70,80,90],
      selectedDefaultP: [10,20,30,40,50,54,56,58,60,70,80,90],
      selectedDefaultPM: [10,20,30,40,50,54,56,58,60,70,80,90],
      selected: [],
      selectedColumns: [
	        
      ]
    }
  },
  methods: {
	dateChanged()
	{
	    	this.getData();
	},
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
	openModule(what, id)
	{
		this.$refs.editor.open( what, id);
	},
    show( line)
    {
    	alert( JSON.stringify( line.placementIds))
    },
    ctxMenu(event, data, idx) 
    { 
       event.preventDefault();
       this.$refs.menu.open(event, {data: data, row: idx} )
    },
	createExcel( ) {
    	this.pbAction = "createExcelInternal";
    	this.pbInfo = "Creating excel";
    	this.showProgressBar = true;
    },
	createExcelInternal(myId, api)
	{
		let that = this;
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		let inputStruct = { search: this.searchTerm};
		api.post( copyWorkflowAPI+"/createExcel4CopyEntries/"+sessionStorage.tenantId
			+"/"+sessionStorage.accessPointId
			+"/"+sessionStorage.unitId
			+"/"+sessionStorage.userId
			+"/"+req.fromDateISO
			+"/"+req.untilDateISO
			+"/"+0
			+"/"+myId, inputStruct
			)

		 .then( response => 
         {
			let downloadResponse = response.data;
          
            forceFileDownload( downloadResponse.file, downloadResponse.filename);
			that.showProgressBar = false;
         	//
            
         }).catch(e => {
        	    showError( that.$toast, "get excel data", e);
        	    that.showProgressBar = false;
               
            });
	},
	
	openLine( line)
	{
		if ( line.status === 0 || line.status === 2 || !line.copyId)
		{
			this.$refs.copyWizzard.open(this.data.provider, this.data.languages, this.data.stati, this.data.mediaFamilies, this.data.copyTypes,  line, null, this.searchTerm, this.houseNumberPerPlayout);
		}
		else 
		{
			this.$refs.copyViewer.open(line);
		}
	},
	getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 21%; height: calc(100vh - 120px); padding: 2ex;"
    },
    getColumnStyle(col, idx)
    {
    	let wTotal = this.widthCalculated;
        return "width: " + (parseInt(col.w)/wTotal*100) +"%; background-color: "+getBG()+";color: "+getFG();
    },
    getColumnDetailStyle(col, line)
    {
     	return "font-size:"+this.fontSize+"px;";
    },
    doOrderBy(data)
    {
       let that = this;
       
    	data.sort(function(a,b) {
	     	for ( var i in that.orderBy)
	     	{
	     		let iCol = that.orderBy[i];
	     		let v1 = a[ iCol.show ];
	     		let v2 = b[ iCol.show ];
	     		
	     		if ( ! v2 || v1 > v2 )
	     		{
	     			return iCol.sorting;
	     		}
	     		if ( ! v1 || v1 < v2 )
	     		{
	     			return -iCol.sorting;
	     		}
	     	}
	     	return a.index - b.index;
	     });
		return data;
    },
    doSearch( force) {
	  	if ( force || (this.searchTerm))
		{
	    	this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
	    	this.limit( this.data);
		}
		
	  },
	  applySearch( data)
	    {
	    	let filtered = [];
			this.search = this.searchTerm ? this.searchTerm.toLowerCase(): "*";
	    	for (let di in data)
	    	{
	    		let line = data[di];
	    		line.display = [];
				
	    		let match = !this.search || this.search==="*";
	    		for (let ci in this.selectedColumns)
	    		{
	    			let col = this.selectedColumns[ci];
	    			let displayValue = this.print( line, col);
	    			line.display[ col.id ] = displayValue;
	    			if ( displayValue )
	    			{
						//alert( displayValue.toString().toLowerCase() +"//"+this.searchTerm+displayValue.toString().toLowerCase().includes(this.search))
		    			match = (match || displayValue.toString().toLowerCase().includes(this.search));
	    			}
	    		}
	    		if ( match )
	    		{
	    			filtered.push( line);
	    		}
	    	}
	    	return filtered;
	    },
    limit( data)
    {
        //let tmp = [...data];
        //let tmp = this.doFilter( data);
		let tmp = data.filter( p=>p.status==this.status)
        tmp = this.applySearch( tmp);
    	let array = this.doOrderBy( tmp);
    	if ( this.newLine )
    	{
    		array.push( this.newLine);
    	}
    	
    	return array;
    },
    hoverLine(line)
    {
    	
    	if ( isDarkMode())
    	{
    		$('#'+line).children().addClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().addClass('reqLineBG');
    	}
    },
    leaveLine(line)
    {
    	if ( isDarkMode())
    	{
    		$('#'+line).children().removeClass('reqLineBGDM');
    	}
    	else
    	{
    		$('#'+line).children().removeClass('reqLineBG');
    	}
    },
    print( line, col)
    {
       //return spot[ col.show ];
       let xLine = line;
       let val = null;
       if ( col.type==='icon' )
       {
   	     return "";
   	   }
   	   if ( col.show.endsWith("Ids"))
       {
		   val = xLine[ col.show ].length;
    	   return val;
       } 
       if ( col.show==='provider')
       {
		  val = this.data.provider.find( p=>p.id===line.availabilityId).label;
		  return val;
	   }
	   if ( col.show==='houseno')
       {
   		  val = this.data.houseNumbers[line.id];
   		  return val;
   	   }
	   if ( col.show==='mediaFamily')
       {
		  if ( line.mediaFamilyIds )
		  {
   		  	val = this.data.mediaFamilies.filter( p=>line.mediaFamilyIds.includes(p.id)).map(p=>p.label.substring(0,3));
   		  	return val.join(", ");
		  }
		  return "";
   	   }
	   if ( col.show==='type')
       {
		  if ( line.typeId )
		  {
   		  	val = this.data.copyTypes.find( p=>p.id===line.typeId).label;
   		  	return val;
		  }
		  return "";
   	   }
	   if ( col.show==='budgetDetails')
       {
		   val = xLine.budgetDetailsHC;
		   return format( val);
	   }
       if ( col.show.includes("."))
       {
    	   let path = col.show.split('.');
    	   let tmp = xLine[ path[0] ];
    	   if ( tmp )
    	   {
    	   		val = tmp[ path[1]];
       	   }
       } 
       else
       {
    	   val = xLine[ col.show ];
       }
       if ( col.type==='time' )
    	{
    	   return printTimeMMSS(val);
    	}
    	
       if ( col.type==='perc' )
       {
   	     return (Math.round( 100 * val)/100)+'%';
   	   }

    	if ( ! val )
    	{
    		return "";
    	}
    	
    	if ( col.boolean )
    	{
    		if ( val )
    		{
    			return "X";
    		}
    		return "";
    	}
    	if ( isNaN( val) )
    	{
    		return val?val:"";
    	}
    	try
    	{
    		
    		return val ? formatNumber( val ) : val;
    	}
    	catch(e) {
    		//
    	}
    },
	deleteEntry( id)
	{
		let that = this;
		HTTP.delete( fwAPI+"/delete/"+sessionStorage.tenantId
		       				+"/"+sessionStorage.accessPointId
		       				+"/"+sessionStorage.userId
		       				+"/CopyEntry"
		       				+"/"+id
		       				)
			.then( response => {
				that.getData();
		}).catch(e => {
    	    showError( that.$toast, "get copy data", e);
        });
							                 
	},
    getData( ) {
    	this.pbAction = "getDataInternal";
    	this.pbInfo = "Loading data";
    	this.showProgressBar = true;
    },
    getDataInternal( myId, api ) {
        let that = this;
		
		let req = {	 
					fromDateISO: momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0], 
					untilDateISO: momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0] 
		};
		let inputStruct = { search: this.searchTerm};
		let dataLoad = new Promise(function (resolve, reject) {
            
       		api.post( copyWorkflowAPI+"/getCopyEntry/"+sessionStorage.tenantId
       				+"/"+sessionStorage.accessPointId
       				+"/"+sessionStorage.unitId
       				+"/"+sessionStorage.userId
       				+"/"+req.fromDateISO
       				+"/"+req.untilDateISO
					+"/"+0
       				+"/"+myId, inputStruct
       				)

        		 .then( response => 
                 {
					
                 	that.data = response.data;
                 	that.dataSave = response.data;
					that.houseNumberPerPlayout = response.data.houseNumberPerPlayout;
					that.validProvider = that.data.provider.filter(p=>p.available).map( p=> { return {id: p.id, label: p.name, visible: true}});
					that.validProvider.sort((a,b)=>a.label.localeCompare( b.label))
					that.data.provider = that.data.provider.map( p=> { return {id: p.id, label: p.name, visible: true}});
					that.data.mediaFamilies = that.data.mediaFamilies.map( p=> { return {id: p.id, label: p.name, visible: true}});
					//alert( JSON.stringify(that.data.mediaFamilies))
					that.data.copyTypes = that.data.copyTypes.map( p=> { return {id: p.id, label: p.name, visible: true, default: p.isDefault}});
					//alert( JSON.stringify(that.data.copyTypes))
					that.data.languages = that.data.languages.map( p=> { return {id: p.id, label: p.name, visible: true}});
					
					//alert( JSON.stringify( that.data.stati))
					that.data.stati = that.data.stati.map( p=> { return {id: that.data.stati.indexOf( p), label: p, visible: true}});
					that.nStati = [];
					for ( let nStat in that.data.stati)
					{
						let statId = that.data.stati[nStat].id;
						that.nStati[ statId] = that.data.entries.filter( p=>p.status==statId).length
					}
                 	that.selectedDefault = that.selectedDefaultD;
                 	//alert( JSON.stringify( that.data.stati))
                 	that.selected = null;
                 	that.arrangeColumns();
                 	that.ready = true;
					
					that.showProgressBar = false;
                    resolve (true);     
                    
                 }).catch(e => {
                	    showError( that.$toast, "get copy data", e);
                	    that.showProgressBar = false;
                        reject ();
                    });
	      });
    },

    goBack()
    {
    	this.data = [];
    	this.selectedDefault = [...this.selectedSave];
    	this.selected = null;
        this.arrangeColumns();
    	for ( let i in this.dataSave)
    	{
    		this.data.push( this.dataSave[i]);
    	}

    },
    getOrderBy( col )
    {
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( iCol.sorting > 0 )
    		    {
    				return "A"+(parseInt(i)+1);
    			}
    			return "D"+(parseInt(i)+1);
    		}
    	}
    	return "";
    },
   setOrderBy( col )
    {
       if ( col.type === 'icon' || col.type === 'progress')
       {
    	   return;
       }
       var add = true;
    	for ( var i in this.orderBy)
    	{
    		let iCol = this.orderBy[i];
    		if ( iCol.show === col.show )
    		{
    		    if ( col.sorting === 1 )
    		    {
    		    	col.sorting = -1;
    		    }
    		    else
    		    {
    				this.orderBy.splice( i, 1);
    				col.sorting = 0;
    			}
    			add = false;
    			break;
    		}
    	}
    	if ( add )
    	{
    		this.orderBy.push( col );
    		col.sorting = 1;
    	}
    	//this.doOrderBy();
    	this.$forceUpdate();
    	
    },
	arrangeColumns()
	{
		this.availableColumns = this.availableColumns.sort((a,b)=>a.name.localeCompare(b.name));
	    this.selectedColumns = new Array();
	    //alert( JSON.stringify( this.selectedIN))
	    if ( this.selectedIN)
	    {
	    	try
	    	{
	    		this.selected = this.selectedIN.split(',');
	    		//alert( this.selected)
	        } catch (e)
	        {
	        	//alert(JSON.stringify(e))
	        	this.selected = [];
	        }
	    	
	    }
	    //alert( JSON.stringify( this.selected))
	    if ( ! this.selected || ! this.selected.length )
	    {
	    	this.selected = [];
	    	
	    	for ( let i in this.selectedDefault )
			{
				let col = this.availableColumns.find( p=> p.id===this.selectedDefault[i])
				this.selected.push( col.id);
			}
	    }
	    //console.log(JSON.stringify(this.selected))
		for ( let i in this.selected )
		{
			let id = parseInt(this.selected[i]);
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.id === id )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	calcWidths( withSaving)
	{
	    let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].id;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].id;
     		}
     	}
		this.widthCalculated = wTot;
		
	},
    storeSettings() 
    {
		let obj = restore();
		if ( !obj )
		{
			obj = {};
		}
		obj.selectedColumns = this.selectedColumns;
		obj.selectedDefault = this.selectedDefault;
		obj.fromDate = this.fromDate;
		obj.untilDate = this.untilDate;
		obj.validProvider = this.validProvider;
		obj.searchTerm = this.searchTerm;
		store( obj);
    },

  },
  	
  beforeDestroy() {
	 this.storeSettings();

  },
  created() {
	  	let restoreView = restore();
	  	if ( restoreView && restoreView.data )
	  	{
			this.selectedColumns = restoreView.selectedColumns;
			this.selectedDefault = restoreView.selectedDefault;
			this.fromDate = restoreView.fromDate;
			this.untilDate = restoreView.untilDate;
			this.searchTerm = restoreView.searchTerm;
			this.validProvider = restoreView.validProvider;
			this.ready = true;
			this.arrangeColumns();
			this.calcWidths();
			
			this.setOrderBy( {show: 'client'});
		}
		else
		{
			let d = new Date();
			this.fromDate = new Date(d.getFullYear(), 0, 1);
			this.untilDate = new Date(d.getFullYear(), 11, 31);
			this.fromDate = momentTZ(this.fromDate).utcOffset(0, true).format().split('T')[0]
			this.untilDate = momentTZ(this.untilDate).utcOffset(0, true).format().split('T')[0]
			this.arrangeColumns();
			this.calcWidths();
			
			this.setOrderBy( {show: 'name'});
			this.getData();
		}	  
		initAppMode();
	    

  },
  watch: {
  	fromDate: function() { this.untilDate = new Date(this.fromDate.getFullYear(), 11, 31); },
  	untilDate: function() {  },
  	
  },
  updated() {
  }
}
</script>
<style lang="scss" scoped>
 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11pt !important; 
  font-weight: bold; 
  width: 100% !important; 
  height: 18pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  }
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  overflow: hidden; 
  text-overflow: ellipsis;
}
.centered {
  justify-content: center;
}
.right {
  justify-content: right;
}
.left {
  justify-content: left;
}
.floatLeft {
    display: inline-flex;
    float: left;
}
.reqLineBG {
  background-color: #e8f0e8 !important; 
}
.reqLineBGDM {
  background-color: #aca !important; 
  color: #000 !important;
}
.ltd {
  padding-left: 1pt;
  padding-right: 1pt;
  overflow-x: hidden;
  font-size: 11px;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.reqDetail { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.reqDetailNB { 
  display: flex;
  align-items: center;
  font-size: 8pt;  
  width: 100% !important; 
  height: 20pt; 
  overflow: hidden;
  cursor:pointer;
  //background-color: #aaa; 
}
.myCreateButton {
	width: 60% !important;
    padding: 5px 1px;
    outline: none;
	justify-content: left;
    border-radius: 4px 4px 4px 4px;
    height: 20pt;
    font-size: 7pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.myTab {
	width: 80pt;
    padding: 5px 11px;
    outline: none;
	font-weight: bold;
    border-radius: 4px 12px 0 0;
    height: 20pt;
    font-size: 7pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 0pt;
    margin-bottom: 0pt;
}
.myTabActive {
	//width: 18%;
    padding: 3px 10px;
    background-color: #aaf;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
} 
</style>

